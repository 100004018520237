import Report from "./Report";
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'
import HomePage from "./Home";

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
  },
    {
        path: '/report/:reportId',
        element: <Report />,
    },
    // 404 page
  {
    path: '*',
    element: <h1>404 Not Found</h1>,
  },
]);



function App() {
  return (
      <RouterProvider router={router} />
  );
}

export default App;
