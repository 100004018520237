import { Line  } from '@ant-design/charts';
import {Row, Col, Table} from 'antd';

const ReportTemplate = (props) => {
    const {userData, spiroDataToUse, loading} = props;

    const styles = {
        h1: {
            margin: 0,
            fontSize: '16px',
        },
        p: {
            margin: 0,
            padding: 0,
            fontSize: '14px',
        },
        dateContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            height: '100%',
        },
        dateContainerP: {
            margin: 0,
            padding: 0,
            fontSize: '14px',
            fontWeight: 'bold',
        },
        patientDetails: {
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
        },
    };

    const spiroData = spiroDataToUse.reduce((acc, curr) => {
        const existingRecord = acc.find(
            (record) => record.measured_at === curr.measured_at,
        );
        if (existingRecord) {
            existingRecord.fvc = (existingRecord.fvc + curr.fvc) / 2;
            existingRecord.fev1 = (existingRecord.fev1 + curr.fev1) / 2;
            existingRecord.pef = (existingRecord.pef + curr.pef) / 2;
            existingRecord.fev1_fvc = (existingRecord.fev1_fvc + curr.fev1_fvc) / 2;
            existingRecord.fef25 = (existingRecord.fef25 + curr.fef25) / 2;
            existingRecord.fef50 = (existingRecord.fef50 + curr.fef50) / 2;
            existingRecord.fef75 = (existingRecord.fef75 + curr.fef75) / 2;
            existingRecord.fef2575 = (existingRecord.fef2575 + curr.fef2575) / 2;
            existingRecord.fev3 = (existingRecord.fev3 + curr.fev3) / 2;
            existingRecord.fev6 = (existingRecord.fev6 + curr.fev6) / 2;
            existingRecord.peft = (existingRecord.peft + curr.peft) / 2;
            existingRecord.evol = (existingRecord.evol + curr.evol) / 2;
            return acc;
        }
        return [...acc, curr];
    }, []);

    const measurements = [
        "fvc",
        "fev1",
        "pef",
        "fev1_fvc",
        "fef25",
        "fef50",
        "fef75",
        "fef2575",
        "fev3",
        "fev6",
        "peft",
        "evol",
    ];

    let chartData = measurements.reduce((acc, measurement) => {
        acc[measurement] = [];
        return acc;
    }, {});
    const tableData = measurements.map((measurement) => {
            const data = spiroData.map((record) => record[measurement]);
            const reference = spiroData.map((record) => record[`p_${measurement}`]);
            return {
                key: measurement,
                measurement,
                day1: data[0]?.toFixed(2),
                day2: data[1]?.toFixed(2),
                day3: data[2]?.toFixed(2),
                day4: data[3]?.toFixed(2),
                day5: data[4]?.toFixed(2),
                day6: data[5]?.toFixed(2),
                day7: data[6]?.toFixed(2),
                reference: reference[0]?.toFixed(2),
            }
        }
    );

    const columns = [
        {
            title: "Ölçüm",
            dataIndex: "measurement",
            key: "measurement",
            render: text => <b>{text.toUpperCase()}</b>,
        },
        {
            title: "1. Gün",
            dataIndex: "day1",
            key: "day1",
            render: (text, record) => <div style={{textAlign: 'right', color: text < record.reference - 1 || text > record.reference + 1 ? 'red' : 'green' }}>{text}</div>,
        },
        {
            title: "2. Gün",
            dataIndex: "day2",
            key: "day2",
            render: (text, record) => <div style={{textAlign: 'right', color: text < record.reference - 1 || text > record.reference + 1 ? 'red' : 'green' }}>{text}</div>,
        },
        {
            title: "3. Gün",
            dataIndex: "day3",
            key: "day3",
            render: (text, record) => <div style={{textAlign: 'right', color: text < record.reference - 1 || text > record.reference + 1 ? 'red' : 'green' }}>{text}</div>,
        },
        {
            title: "4. Gün",
            dataIndex: "day4",
            key: "day4",
            render: (text, record) => <div style={{textAlign: 'right', color: text < record.reference - 1 || text > record.reference + 1 ? 'red' : 'green' }}>{text}</div>,
        },
        {
            title: "5. Gün",
            dataIndex: "day5",
            key: "day5",
            render: (text, record) => <div style={{textAlign: 'right', color: text < record.reference - 1 || text > record.reference + 1 ? 'red' : 'green' }}>{text}</div>,
        },
        {
            title: "6. Gün",
            dataIndex: "day6",
            key: "day6",
            render: (text, record) => <div style={{textAlign: 'right', color: text < record.reference - 1 || text > record.reference + 1 ? 'red' : 'green' }}>{text}</div>,
        },
        {
            title: "7. Gün",
            dataIndex: "day7",
            key: "day7",
            render: (text, record) => <div style={{textAlign: 'right', color: text < record.reference - 1 || text > record.reference + 1 ? 'red' : 'green' }}>{text}</div>,
        },
        {
            title: "Referans",
            dataIndex: "reference",
            key: "reference",
            render: text => <div style={{textAlign: 'right'}}>{text}</div>,
        },
    ]


    measurements.forEach(measurement => {
        // For each day
        for (let day = 1; day <= 7; day++) {
            // Find the data for this day and measurement
            let dayData = spiroData.find((record, index) => index === day - 1);
            if (dayData) {
                // Create a new object and add it to the array
                chartData[measurement].push({
                    day: `${day}. Gün`,
                    value: dayData[measurement],
                });
            }
        }
    });


    return (
        <div style={{padding:16}}>
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={20}>
                            <div>
                                <h1>Ad Soyad: {userData.name}</h1>
                                <p>Yaş: {userData.age}</p>
                                <p>Boy(cm): {userData.height} </p>
                            </div>

                        </Col>
                        <Col span={4}>
                            <div style={styles.dateContainer}>
                                <p style={styles.dateContainerP}>Tarih: {new Date().toLocaleDateString("tr-TR", {
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                })}</p>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row>
                        <Col span={24}>
                            <Table
                                loading={loading}
                                columns={columns}
                                dataSource={tableData}
                                bordered={true}
                                pagination={false}
                                style={{
                                    width: '100%',
                                }}
                                scroll={{
                                    x: 'max-content',
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                {measurements.map(measurement => {
                    const config = {
                        data: chartData[measurement],
                        xField: 'day',
                        yField: 'value',
                        yAxis: {
                            label: {
                                formatter: function formatter(v) {
                                    return ''.concat(v, ' L');
                                },
                            },
                        },
                        height: 200,
                        style: {
                            lineWidth: 3,
                        },
                        legend: { position: 'bottom' },
                        responsive: true,
                    };
                    return (
                        <Col xxl={12} xl={12} lg={24} md={24} sm={24} xs={24}>
                            <div key={measurement}>
                                <h2>{measurement.toUpperCase()}</h2>
                                <Line {...config} />
                            </div>
                        </Col>

                    );
                })}

            </Row>
        </div>
    )
}


export default ReportTemplate;
