import {Col, Row} from "antd";
import {useEffect} from "react";

const HomePage = () => {
    useEffect(() => {

    }, []);
    return (
        <Row justify="center" align="center">
        <Col>
            <h1>Home Page</h1>
        </Col>
        </Row>
    );
}

export default HomePage;
