import {useEffect, useRef, useState} from "react";
import {Col, message, Row, Spin} from "antd";
import ReportTemplate from "./reportTemplate";
import {useParams} from "react-router-dom";

const getSpiroData = async (reportId, setLoading) => {
    setLoading(true)
    const response = await fetch(`https://api.fluxivo.com/api/v1/reports/report/${reportId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        }

    });
    const data = await response.json();
    if(data.message) {
        return message.error(data.message);
    }
    setLoading(false);
    return data;
}

const Report = () => {
    const reportTemplateRef = useRef(null);
    const [spiroData, setSpiroData] = useState([]); // [{}
    const [userData, setUserData] = useState({}); // {name: 'John Doe'}
    const [loading, setLoading] = useState(true);

    const {reportId} = useParams();



    useEffect(() => {
        getSpiroData(reportId, setLoading).then(data => {
            setSpiroData(data?.spiroData);
            setUserData(data?.userData);
        });
    }, [reportId]);

    /* const handleGeneratePDF = () => {
        const doc = new jsPDF({
            format: 'a4',
            orientation: 'portrait',
            unit: 'px',
        });
        doc.html(reportTemplateRef.current, {
            callback: function (doc) {
                doc.setDisplayMode('fullwidth', 'continuous', 'FullScreen')
                doc.save('report.pdf');
            }
        });
    } */

    return (
        <>
            {loading ?
                <Spin size="large"  tip={"Lütfen Bekleyin..."} style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    width: '100vw',
                }} />
                : <Row ref={reportTemplateRef} justify="center">
                <Col xs={24} md={24} lg={18} xl={12} xxl={12}><ReportTemplate
                    userData={userData}
                    spiroDataToUse={spiroData}
                    loading={loading}
                /></Col>
            </Row>}

            {/*<div>
                <button onClick={handleGeneratePDF}>Generate PDF</button>
            </div>*/}
        </>
    )

}

export default Report;
